.required label:after {
    color: #d00;
    content: " *"
}

.remove-min-height {
    min-height: 0;
}

// List on Admin Tenant view
ul.list-margin-bottom {
    li:not(.heading) {
        padding-bottom: 7px;
        margin-bottom: 2px;
        border-bottom: 1px solid #d2d6de;
    }

    margin-bottom: 10px;
}

.box-body {
    h4.box-title {
        border-bottom: 1px solid #f4f4f4;
        padding-bottom: 10px;
    }
}
